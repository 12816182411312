import AppContext from "app/contexts/AppContext";
import React from "react";
import TaskTable from "app/components/reports/TaskTable";
import { Accordion, Badge, Button, Card, Col, Form, Row } from "react-bootstrap";
import { FBService } from "app/services/FBService";
import { IControlArea } from "app/components/reports/ReportTypes";
import { IFBCase, IFBProject } from "app/services/FBTypes";


interface IOpenCaseState {
    area: IControlArea;
    cases: IFBCase[];
}

class OpenCases extends React.Component<{}, IOpenCaseState> {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    state: IOpenCaseState = {
        area: CaseAreaData.ALL,
        cases: []
    };

    onUpdateClick = async (e: React.MouseEvent) => {

        if (this.context.token) {
            this.context.onLoadingChange(true);

            const cases = await FBService.fetchCases(this.context.token, this.state.area.query);
            this.setState({ cases });
            this.context.onLoadingChange(false);
        }
    };

    renderControls() {

        return (
            <Form className="controls mb-3">
                <Form.Row className="align-items-end">
                    <Form.Group as={Col} xs={8}>
                        <Form.Label>Area</Form.Label>
                        {Object.keys(CaseAreaData).map((key, index) => {

                            const data = CaseAreaData[key];

                            return <Form.Check
                                type="radio"
                                label={data.label}
                                name="controlsArea"
                                id={data.id}
                                key={data.id}
                                defaultChecked={data.id === this.state.area.id}
                                onClick={() => this.setState({ area: data })}
                                inline
                            />;
                        })}
                    </Form.Group>
                    <Form.Group className="text-right" as={Col} xs={4}>
                        <Button onClick={this.onUpdateClick}>Update</Button>
                    </Form.Group>
                </Form.Row>
            </Form>
        );
    }

    renderProjects() {

        if (this.state.cases.length > 0) {

            const projects = getCasesByProject(this.state.cases, this.context.projects);
            let eventKey: string;

            const innerHtml = projects.map((item, index) => {

                eventKey = index.toString();

                return (
                    <Card key={item.ixProject}>
                        <Accordion.Toggle as={Card.Header} eventKey={eventKey}>
                            {this.renderProjectHeader(item)}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={eventKey}>
                            <Card.Body>
                                <TaskTable tasks={item.cases} badgeKey="sArea" dateKey="dtDue" dateLabel="Due" dateFlag={true} />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                );
            });

            return (
                <Row>
                    <Col>
                        <Accordion defaultActiveKey="0">
                            {innerHtml}
                        </Accordion>
                    </Col>
                </Row>
            );
        }

        return null;
    }

    renderProjectHeader(project: ICasesByProject) {

        // Hours Icons: history, history_toggle, schedule, watch_later, update
        const hoursFlag = (project.pastEstTotal > 0) ? (<i className="material-icons-outlined md-24 text-danger">schedule</i>) : null;

        // Due Date Icons: insert_invitation, date_range, calendar_today
        const dueFlag = (project.pastDueTotal > 0) ? (<i className="material-icons-outlined md-24 text-danger">insert_invitation</i>) : null;

        return (
            <Row className="align-items-center">
                <Col>
                    <strong>{project.sProject}</strong>&ensp;<Badge variant="info">{project.cases.length}</Badge>
                </Col>
                <Col xs={1} className="text-right">
                    {hoursFlag} {dueFlag}
                </Col>
            </Row>
        );
    }

    render() {
        return (
            <div>
                <Row>
                    <Col className="mb-1">
                        <h2>Open Cases Report</h2>
                    </Col>
                </Row>
                {this.renderControls()}
                {this.renderProjects()}
            </div>
        );
    }
}

export default OpenCases;



/*****************************************************************************/
const CaseAreaData: { [key: string]: IControlArea } = {
    ALL: {
        id: "all",
        label: "All",
        query: `status:open project:'${FBService.UK_PROJECT_PREFIX}'`
    },
    DEV: {
        id: "dev",
        label: "Development",
        query: `status:open project:'${FBService.UK_PROJECT_PREFIX}' area:Development`
    },
    PM: {
        id: "pm",
        label: "Project Management",
        query: `status:open project:'${FBService.UK_PROJECT_PREFIX}' area:Management OR area:Testing`
    }
};



/*****************************************************************************/
interface ICasesByProject extends IFBProject {
    cases: IFBCase[];
    resolvedTotal: number;
    hrsCurrEstTotal: number;
    hrsElapsedTotal: number;
    pastEstTotal: number;
    pastDueTotal: number;
}

const getCasesByProject = (cases: IFBCase[], projects: IFBProject[]) => {

    const arr: ICasesByProject[] = [];
    const dt = new Date();

    cases.forEach(item => {

        let proj = arr.find(p => p.ixProject === item.ixProject);

        if (!proj) {
            const source = projects.find(p => p.ixProject === item.ixProject);

            if (source) {
                proj = Object.assign({
                    cases: [],
                    resolvedTotal: 0,
                    hrsCurrEstTotal: 0,
                    hrsElapsedTotal: 0,
                    pastEstTotal: 0,
                    pastDueTotal: 0
                }, source);
                arr.push(proj);
            }
        }

        if (!proj)
            throw new Error(`Project "${item.sProject}" does not exist.`);

        proj.cases.push(item);
        proj.resolvedTotal += Number(!!item.dtResolved);
        proj.hrsCurrEstTotal += item.hrsCurrEst;
        proj.hrsElapsedTotal += item.hrsElapsed;
        proj.pastEstTotal += Number(!!(item.hrsElapsed > item.hrsCurrEst));
        proj.pastDueTotal += Number(!!(item.dtDue && item.dtDue < dt));
    });

    return arr.sort((a, b) => a.sProject.localeCompare(b.sProject));
};
