

export class ChartConfig {

    static readonly axisWidth = 144;
    static readonly axisHeight = 32;
    static readonly barCategoryGap = 6;
    static readonly barGap = 2;
    static readonly barSize = 48;
    static readonly height = 432;
    static readonly legendHeight = 24;
    static readonly margin = {
        top: 5, bottom: 5, left: 5, right: 5
    };

    static dynamicHeight(numCategories: number, numValues = 1, bixaxial = false, legend = false) {

        // This calculation is off by a few pixels, unsure what is not being accounted for...
        return ChartConfig.barSize * numCategories * numValues
            + ChartConfig.barCategoryGap * numCategories
            + ChartConfig.axisHeight
            + Number(bixaxial) * ChartConfig.axisHeight
            + Number(legend) * ChartConfig.legendHeight
            + ChartConfig.margin.top + ChartConfig.margin.bottom;
    }

    static material(value: number, random = false) {
        // https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=FFA726

        const colors100 = ["#ff8a80", "#ff80ab", "#ea80fc", "#b388ff", "#8c9eff", "#82b1ff", "#80d8ff", "#84ffff", "#a7ffeb", "#b9f6ca", "#ccff90", "#f4ff81", "#ffe57f", "#ffd180", "#ff9e80"];
        const colors200 = ["#ff5252", "#ff4081", "#e040fb", "#7c4dff", "#536dfe", "#448aff", "#40c4ff", "#18ffff", "#64ffda", "#69f0ae", "#b2ff59", "#eeff41", "#ffff00", "#ffd740", "#ffab40", "#ff6e40"];
        const colors400 = ["#ff1744", "#f50057", "#d500f9", "#651fff", "#3d5afe", "#2979ff", "#00b0ff", "#00e5ff", "#1de9b6", "#00e676", "#76ff03", "#c6ff00", "#ffea00", "#ffc400", "#ff9100", "#ff3d00"];
        const colors700 = ["#d50000", "#c51162", "#aa00ff", "#6200ea", "#304ffe", "#2962ff", "#0091ea", "#00b8d4", "#00bfa5", "#00c853", "#64dd17", "#aeea00", "#ffd600", "#ffab00", "#ff6d00", "#dd2c00"];

        const pool = [...colors100, ...colors200, ...colors400, ...colors700];

        const ary = [];
        const gap = Math.floor(pool.length / value) || 1;
        let i = 0;

        while (ary.length < value) {
            ary.push(pool[i % pool.length]);
            i += gap;
        }

        return ary;
    }

    static palette(value: number) {
        // Color generated by [Colorgorical](http://vrl.cs.brown.edu/color)

        if (value <= 4)
            return ["#01c472", "#e71761", "#1ceaf9", "#7b2c31"];
        if (value <= 8)
            return ["#52ef99", "#f23b65", "#19a71f", "#e84fe1", "#65f112", "#7922b4", "#87aa23", "#785bae", "#fbd127", "#881d2a", "#4be8f9", "#c5667d"];
        if (value <= 12)
            return ["#52ef99", "#f23b65", "#19a71f", "#e84fe1", "#65f112", "#7922b4", "#87aa23", "#785bae", "#fbd127", "#881d2a", "#4be8f9", "#c5667d"];
        if (value <= 16)
            return ["#b4ddd4", "#881c23", "#5cf070", "#8d3f90", "#459521", "#d518bd", "#a7d64e", "#7129ed", "#fea53b", "#10558a", "#f1d438", "#315bf3", "#926026", "#dd8eeb", "#255026", "#fab5b5"];

        // Max 20
        return ["#72e5ef", "#d10f55", "#39f27a", "#f67fec", "#ade64f", "#6633b4", "#728e24", "#fa1bfc", "#3aa609", "#fcc2fb", "#02531d", "#c5d5f0", "#683c00", "#748ed7", "#edd45e", "#76406b", "#fba55c", "#304866", "#ec4b18", "#2da0a1"];
    }
}