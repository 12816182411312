import AppContext from "app/contexts/AppContext";
import React from "react";
import { Spinner } from "react-bootstrap";
import "app/components/misc/AppSpinner.css";


class AppSpinner extends React.Component {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    render() {

        if (this.context.loading)
            return (
                <div id="AppSpinner">
                    <div className="spinnerBox">
                        <div className="spinnerWrapper">
                            <Spinner animation="border" role="status" />
                        </div>
                        <div className="spinnerText">Loading...</div>
                    </div>
                </div>
            );

        return null;
    }
}

export default AppSpinner;
