import React from "react";
import { FBService } from "app/services/FBService";
import { IFBPerson, IFBProject } from "app/services/FBTypes";


/*****************************************************************************/
interface IAppState {
    loading: boolean;
    projects: IFBProject[];
    people: IFBPerson[];
    token?: string;
    tokenValid?: boolean;
}

interface IAppContext extends IAppState {
    onLoadingChange: (busy: boolean) => void;
    onTokenChange: (token?: string) => void;
}

const AppContext = React.createContext<IAppContext>({} as IAppContext);

export class AppStore extends React.Component<{}, IAppState> {

    state: IAppState = {
        loading: false,
        projects: [],
        people: []
    };

    async setToken(token?: string) {

        let loading;
        let tokenValid;

        if (token) {

            loading = true;
            this.setState({ loading });
            const isValid = await FBService.validateToken(token);

            if (isValid) {

                this.setState({ token },
                    async () => {

                        const allProjects = await FBService.fetchProjects(token);
                        const projects = allProjects.filter(item => item.sProject.indexOf(FBService.UK_PROJECT_PREFIX) > -1);
                        projects.forEach(item => {
                            item.sProject = item.sProject.replace(FBService.PROJECT_PREFIX_PATTERN, "");
                        });

                        const people = await FBService.fetchPeople(token);

                        loading = false;
                        tokenValid = true;

                        this.setState({
                            loading,
                            projects,
                            people,
                            tokenValid
                        });
                    }
                );
            }
            else {

                loading = false;
                tokenValid = false;

                this.setState({ loading, tokenValid });
            }
        }
        else {
            this.setState({
                token: undefined,
                tokenValid: undefined
            });
        }
    }

    render() {
        return (
            <AppContext.Provider value={{
                ...this.state,
                onLoadingChange: (loading) => this.setState({ loading }),
                onTokenChange: (token) => this.setToken(token)
            }}>
                {this.props.children}
            </AppContext.Provider>
        );
    }
}

export default AppContext;