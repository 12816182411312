import AppSpinner from "app/components/misc/AppSpinner";
import Dashboard from "app/components/nav/Dashboard";
import React from "react";
import Report from "app/components/reports/Report";
import { AppStore } from "app/contexts/AppContext";
import { HashRouter } from "react-router-dom";
import "app/Variables.css";
import "app/Overrides.css";
import "app/App.css";


class App extends React.Component {

    render() {

        return (
            <AppStore>
                <div id="App">
                    <HashRouter>
                        <Report />
                        <Dashboard />
                        <AppSpinner />
                    </HashRouter>
                </div>
            </AppStore>
        );
    }
}

export default App;
