import { Date2 } from "app/utils/Date2";
import { IFBArea, IFBCaseResponse, IFBIntervalResponse, IFBPerson, IFBProject, IFBToken } from "app/services/FBTypes";


/*****************************************************************************/
const API_URL = "https://papirfly.fogbugz.com/f/api/0/jsonapi";
const API_METHOD = "POST";


/*****************************************************************************/
export class FBService {

    static readonly PROJECT_PREFIX_PATTERN = /^.+? [-|–|—|―] /i;

    static readonly DK_PROJECT_PREFIX = "Papirfly Aps";
    static readonly UK_PROJECT_PREFIX = "Papirfly UK";

    static readonly SUPPORT_PROJECT = "Papirfly Support Inbox";

    static readonly UK_DOMAIN = "papirfly.com";


    /**
     * Will bulk delete all interval IDs listed in the array if authenticated
     * user has administrator permission.
     * 
     * *NOTE: Be very careful using this function!*
     */
    static async bulkDeleteIntervals(token: string, intervals: number[]) {

        const deleteInterval = async (ixInterval: number) => {
            await fetch(API_URL, {
                method: API_METHOD,
                body: JSON.stringify({
                    "cmd": "deleteInterval",
                    "token": token,
                    "ixInterval": ixInterval
                }),
            });
        };

        intervals.forEach(item => {
            deleteInterval(item);
        });
    }

    /**
     * Be very careful using this function!  Currently setup to change customer name.
     */
    static async bulkEditCases(token: string, cases: number[], customer: string) {

        const edit = async (ixBug: number) => {
            await fetch(API_URL, {
                method: API_METHOD,
                body: JSON.stringify({
                    "cmd": "edit",
                    "token": token,
                    "ixBug": ixBug,
                    "plugin_customfields_at_fogcreek_com_clientk66": customer
                }),
            });
        };

        cases.forEach(item => {
            edit(item);
        });
    }

    static async fetchArea(token: string, ixProject: number) {

        const response = await fetch(API_URL, {
            method: API_METHOD,
            body: JSON.stringify({
                "cmd": "listAreas",
                "token": token,
                "ixProject": ixProject
            }),
        });

        const json = await response.json();
        return json.data.areas as IFBArea[];
    }

    /**
     * Retrieves cases using the `search` command.
     * 
     * **Search Syntax for `q`:**  
     * [Searching in FogBugz: Syntax and the Search Axis](https://support.fogbugz.com/hc/en-us/articles/360011257174-Searching-in-FogBugz-Syntax-and-the-Search-Axis)
     * 
     * **Notes:**
     * Search terms that include spaces must be encapsulted in quotes.
     */
    static async fetchCases(token: string, query?: string, max?: number) {

        query = query || `status:open project:'${FBService.UK_PROJECT_PREFIX}' area:Development`;

        const response = await fetch(API_URL, {
            method: "POST",
            body: JSON.stringify({
                "cmd": "search",
                "q": query,
                "token": token,
                "max": max,
                "cols": [
                    "ixBug",
                    "sTitle",
                    "ixProject",
                    "sProject",
                    "ixArea",
                    "sArea",
                    "ixStatus",
                    "sStatus",
                    "ixPriority",
                    "sPriority",
                    "ixFixFor",
                    "sFixFor",
                    "dtFixFor",
                    "dblStoryPts",
                    "hrsOrigEst",
                    "hrsCurrEst",
                    "hrsElapsed",
                    "ixCategory",
                    "sCategory",
                    "ixPersonAssignedTo",
                    "sPersonAssignedTo",
                    "ixPersonOpenedBy",
                    "ixPersonResolvedBy",
                    "ixPersonClosedBy",
                    "ixPersonLastEditedBy",
                    "dtOpened",
                    "dtResolved",
                    "dtClosed",
                    "dtDue",
                    "client"
                ]
            })
        });

        const json = await response.json();
        const raw = json.data.cases as IFBCaseResponse[];

        const data = raw.map(({
            ixBug,
            sTitle,
            ixProject,
            sProject,
            ixArea,
            sArea,
            ixStatus,
            sStatus,
            ixPriority,
            sPriority,
            ixFixFor,
            sFixFor,
            dtFixFor,
            dblStoryPts,
            hrsOrigEst,
            hrsCurrEst,
            hrsElapsed,
            ixCategory,
            sCategory,
            ixPersonAssignedTo,
            sPersonAssignedTo,
            ixPersonOpenedBy,
            ixPersonResolvedBy,
            ixPersonClosedBy,
            ixPersonLastEditedBy,
            dtOpened,
            dtResolved,
            dtClosed,
            dtDue,
            client,
        }) => {

            const aProject = sProject.replace(FBService.PROJECT_PREFIX_PATTERN, "");
            const aTitle = sTitle.replace(` - ${aProject}`, "");
            const aClient = client.replace(FBService.PROJECT_PREFIX_PATTERN, "");

            return {
                ixBug,
                sTitle: aTitle,
                ixProject,
                sProject: aProject,
                ixArea,
                sArea,
                ixStatus,
                sStatus,
                ixPriority,
                sPriority,
                ixFixFor,
                sFixFor,
                dtFixFor: (dtDue) ? new Date(dtFixFor) : undefined,
                dblStoryPts,
                hrsOrigEst: Math.max(0, hrsOrigEst),
                hrsCurrEst: Math.max(0, hrsCurrEst),
                hrsElapsed: Math.max(0, hrsElapsed),
                ixCategory,
                sCategory,
                ixPersonAssignedTo,
                sPersonAssignedTo,
                ixPersonOpenedBy,
                ixPersonResolvedBy,
                ixPersonClosedBy,
                ixPersonLastEditedBy,
                dtOpened: new Date(dtOpened),
                dtResolved: (dtResolved) ? new Date(dtResolved) : undefined,
                dtClosed: (dtClosed) ? new Date(dtClosed) : undefined,
                dtDue: (dtDue) ? new Date(dtDue) : undefined,
                client: aClient
            };
        });

        return data;
    }

    /**
     * Retrieves all intervals within the specified dates.
     * 
     * *NOTE: When retrieving intervals for a specific case, it seems you must also specify the person.*
     */
    static async fetchIntervals(token: string, startDate: Date, endDate: Date, ixPerson?: number, ixBug?: number) {

        const response = await fetch(API_URL, {
            method: API_METHOD,
            body: JSON.stringify({
                "cmd": "listIntervals",
                "token": token,
                "ixBug": ixBug,
                "ixPerson": ixPerson,
                "dtStart": Date2.toUSDateString(startDate),
                "dtEnd": Date2.toUSDateString(endDate)
            }),
        });

        const json = await response.json();
        const raw = json.data.intervals as IFBIntervalResponse[];

        const data = raw.map(({
            ixInterval,
            ixPerson,
            ixBug,
            sTitle,
            dtStart,
            dtEnd,
            fDeleted
        }) => {

            return {
                ixInterval,
                ixPerson,
                ixBug,
                sTitle,
                dtStart: new Date(dtStart),
                dtEnd: new Date(dtEnd),
                fDeleted
            };
        });

        return data;
    }

    static async fetchProjects(token: string): Promise<IFBProject[]> {

        const response = await fetch(API_URL, {
            method: API_METHOD,
            body: JSON.stringify({
                "cmd": "listProjects",
                "token": token,
            }),
        });

        const json = await response.json();
        return json.data.projects as IFBProject[];
    }

    static async fetchPeople(token: string): Promise<IFBPerson[]> {

        const response = await fetch(API_URL, {
            method: API_METHOD,
            body: JSON.stringify({
                "cmd": "listPeople",
                "token": token,
                "fIncludeVirtual": 0
            }),
        });

        const json = await response.json();
        const raw = json.data.people as IFBPerson[];
        const data = raw.filter(item => item.sEmail.indexOf(FBService.UK_DOMAIN) > -1);

        return data;
    }

    static async validateToken(token?: string): Promise<boolean> {

        let isValid: boolean;

        const response = await FBService.fetchToken(token)
            .catch(e => {
                console.error(e);
                isValid = false;
            });

        isValid = response === token;

        return isValid;
    }

    private static async fetchToken(token?: string): Promise<IFBToken> {

        const response = await fetch(API_URL, {
            method: API_METHOD,
            body: JSON.stringify({
                "cmd": "logon",
                "token": token
            }),
        });

        const json = await response.json();
        return json.data.token;
    }
}