import AppContext from "app/contexts/AppContext";
import React from "react";
import { Col, Row } from "react-bootstrap";



class PeopleList extends React.Component {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    renderPeople() {
        return this.context.people.map(item => {
            return (
                <tr key={item.ixPerson} onClick={() => window.open(`https://papirfly.fogbugz.com/f/search/?sSearchFor=editedby:${encodeURIComponent(item.sFullName)}`)}>
                    <td>{item.ixPerson}</td>
                    <td>{item.sFullName}</td>
                    <td>{item.sEmail}</td>
                </tr>
            );
        });
    }

    render() {
        return (
            <div>
                <Row>
                    <Col className="mb-1">
                        <h2>People List</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <table className="table table-striped interactive">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderPeople()}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default PeopleList;
