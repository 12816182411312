import { IFBCase, IFBInterval, IFBPerson } from "app/services/FBTypes";
import { IPerformanceByArea, IProductionProject, IProductionTasks, IProductionTasksByArea, IProductionTasksByProject } from "app/services/ProductionTypes";
import { Math2 } from "app/utils/Math2";


/*****************************************************************************/
export class ProductionService {


    static getTasksByArea(tasks: IFBCase[], intervals: IFBInterval[] = []) {

        const arr: IProductionTasksByArea[] = [];
        let match;

        tasks.forEach(item => {

            match = intervals.find(i => i.ixBug === item.ixBug);

            if (match || intervals.length === 0)
                ProductionService.processArea(arr, item);
        });

        arr.forEach(item => {
            item.tasks.sort((a, b) => a.sProject.localeCompare(b.sProject));
        });
        return arr.sort((a, b) => a.sArea.localeCompare(b.sArea));
    }

    static getTasksByProject(tasks: IFBCase[]) {

        const arr: IProductionTasksByProject[] = [];

        tasks.forEach(item => {
            ProductionService.processProject(arr, item);
        });

        arr.forEach(item => {
            item.tasks.sort((a, b) => a.sProject.localeCompare(b.sProject));
        });
        return arr.sort((a, b) => a.sProject.localeCompare(b.sProject));
    }

    static getTeamMembers(members: string[], people: IFBPerson[]) {

        const arr: IFBPerson[] = [];
        let match;

        members.forEach(member => {

            match = people.find(person => person.sEmail === member);

            if (match)
                arr.push(match);
        });

        return arr;
    }

    static getPerformanceByArea(personTasks: IProductionTasksByArea[], productionTasks: IProductionTasksByArea[]) {

        const arr: IPerformanceByArea[] = [];
        let prod;

        personTasks.forEach(item => {

            prod = productionTasks.find(task => task.sArea === item.sArea);

            arr.push({
                ixArea: item.ixArea,
                sArea: item.sArea,
                person: {
                    dblStoryPtsTotal: item.dblStoryPtsTotal,
                    hrsElapsedTotal: item.hrsElapsedTotal,
                    hrsElapsedAverage: item.hrsElapsedAverage,
                    pctCurrEstTotal: item.pctCurrEstTotal,
                    pctCurrEstAverage: item.pctCurrEstAverage,
                    pctCurrEstWeightedTotal: item.pctCurrEstWeightedTotal,
                    pctCurrEstWeightedAverage: item.pctCurrEstWeightedAverage
                },
                production: {
                    dblStoryPtsTotal: prod?.dblStoryPtsTotal || 0,
                    hrsElapsedTotal: prod?.hrsElapsedTotal || 0,
                    hrsElapsedAverage: prod?.hrsElapsedAverage || 0,
                    pctCurrEstTotal: prod?.pctCurrEstTotal || 0,
                    pctCurrEstAverage: prod?.pctCurrEstAverage || 0,
                    pctCurrEstWeightedTotal: prod?.pctCurrEstWeightedTotal || 0,
                    pctCurrEstWeightedAverage: prod?.pctCurrEstWeightedAverage || 0
                }
            });
        });

        return arr;
    }

    static getProjects(tasks: IFBCase[]) {

        const arr: IProductionProject[] = [];

        tasks.forEach(item => {
            if (!arr.find(proj => proj.ixProject === item.ixProject))
                arr.push({ ixProject: item.ixProject, sProject: item.sProject });
        });

        return arr.sort((a, b) => a.sProject.localeCompare(b.sProject));
    }


    /*************************************************************************/
    private static processArea(areas: IProductionTasksByArea[], task: IFBCase) {

        let area: IProductionTasksByArea | undefined;

        area = areas.find(a => a.sArea.toLowerCase() === task.sArea.toLowerCase());

        if (!area) {

            area = {
                tasks: [],
                ixArea: task.ixArea,
                sArea: task.sArea,
                dblStoryPtsTotal: 0,
                hrsElapsedTotal: 0,
                hrsElapsedAverage: 0,
                pctCurrEstTotal: 0,
                pctCurrEstAverage: 0,
                pctCurrEstWeightedTotal: 0,
                pctCurrEstWeightedAverage: 0
            };

            areas.push(area);
        }

        ProductionService.processTask(area, task);
    }

    private static processProject(areas: IProductionTasksByProject[], task: IFBCase) {

        let area: IProductionTasksByProject | undefined;

        area = areas.find(a => a.sProject.toLowerCase() === task.sProject.toLowerCase());

        if (!area) {

            area = {
                tasks: [],
                ixProject: task.ixProject,
                sProject: task.sProject,
                dblStoryPtsTotal: 0,
                hrsElapsedTotal: 0,
                hrsElapsedAverage: 0,
                pctCurrEstTotal: 0,
                pctCurrEstAverage: 0,
                pctCurrEstWeightedTotal: 0,
                pctCurrEstWeightedAverage: 0
            };

            areas.push(area);
        }

        ProductionService.processTask(area, task);
    }

    private static processTask(group: IProductionTasks, task: IFBCase) {

        group.tasks.push(task);

        group.dblStoryPtsTotal += task.dblStoryPts || 1;

        group.hrsElapsedTotal = Math2.ceil(group.hrsElapsedTotal + task.hrsElapsed, 0.01);
        group.hrsElapsedAverage = Math2.ceil(group.hrsElapsedTotal / group.dblStoryPtsTotal, 0.25);

        group.pctCurrEstTotal += (task.hrsCurrEst - task.hrsElapsed) / task.hrsElapsed * 100;
        group.pctCurrEstAverage = Math2.ceil(group.pctCurrEstTotal / group.tasks.length, 0.01);

        group.pctCurrEstWeightedTotal += (task.hrsCurrEst - task.hrsElapsed) * 100;
        group.pctCurrEstWeightedAverage = Math2.ceil(group.pctCurrEstWeightedTotal / group.hrsElapsedTotal, 0.01);
    }
}