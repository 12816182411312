

export class DateManager {

    private static _instance: DateManager;

    static get instance(): DateManager {

        if (!DateManager._instance)
            DateManager._instance = new DateManager();

        return DateManager._instance;
    }

    static get today(): Date {
        return DateManager.instance._today;
    }

    static get lastyear(): Date {
        return DateManager.instance._lastyear;
    }

    private _today: Date;
    private _lastyear: Date;


    private constructor() {

        this._today = new Date();

        this._lastyear = new Date(this._today.getTime());
        this._lastyear.setFullYear(this._lastyear.getFullYear() - 1);
    }
}