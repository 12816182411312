

export class Date2 {

    static formatDate(date: Date) {
        return `${date.getDate().toString().padStart(2, "0")}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getFullYear()}`;
    }

    static months() {
        return [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];
    }

    static months3() {
        return Date2.months().map(item => item.slice(0, 3));
    }

    static parseFromInput(value: string): Date | undefined {
        const match = value.match(/^\d{4}-\d{2}-\d{2}/);
        if (match) return new Date(value);
        return undefined;
    }

    static toISODateString(date: Date) {
        return date.toISOString().replace(/T.+$/, "");
    }

    static toUSDateString(date: Date) {
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    }
}