import React from "react";
import { CSSVar } from "app/utils/CSSVar";


/*****************************************************************************/
const margin = 8;
const padH = 8;
const padV = 6;
const rectInnerWidth = 56; // enough for 9 characters
const rectInnerHeight = 14;
const rectWidth = rectInnerWidth + padH * 2;
const rectHeight = rectInnerHeight + padV * 2;


/*****************************************************************************/
export class ChartLabel {

    static readonly outerWidth = rectWidth + margin * 2;

    static render(props: any) {

        const { x, y, width, height, value } = props;

        const radius = 4;
        const rectX = x + width + margin;
        const rectY = Math.ceil((height - rectHeight) / 2) + y;

        return (
            <g>
                <rect x={rectX} y={rectY} width={rectWidth} height={rectHeight} rx={radius} fill={CSSVar.hex("secondary")} />
                <text x={rectX + rectWidth / 2} y={rectY + padV} fill="#fff" fontSize={14} textAnchor="middle" dominantBaseline="hanging">
                    {value} cases
            </text>
            </g>
        );
    }
}