export class Object2 {

    static getValue<T>(obj: T, key: string, defaultValue?: any) {

        const value = (obj as { [key: string]: any })[key];

        if (value)
            return value;

        return defaultValue;
    }
}