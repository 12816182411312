import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import "app/components/reports/InfoBox.css";


/*****************************************************************************/
interface IInfoBoxProps {
    width: number;
}

class InfoBox extends React.Component<IInfoBoxProps> {

    renderPopover() {
        return (
            <Popover id="popover-infobox">
                <Popover.Title as="h3">About This Chart</Popover.Title>
                <Popover.Content>{this.props.children}</Popover.Content>
            </Popover>
        );
    }

    render() {
        return (
            <div className="infobox mb-1" style={{ width: this.props.width }}>
                <OverlayTrigger trigger="click" placement="bottom-end" overlay={this.renderPopover()}>
                    <i className="material-icons-outlined md-24 text-secondary">info</i>
                </OverlayTrigger>
            </div >
        );
    }
}

export default InfoBox; 