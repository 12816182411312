import AppContext from "app/contexts/AppContext";
import InfoBox from "app/components/reports/InfoBox";
import React from "react";
import TaskTable from "app/components/reports/TaskTable";
import { Accordion, Badge, Button, Card, Col, Form, Row, Tab, Table, Tabs } from "react-bootstrap";
import { Bar, BarChart, CartesianGrid, Cell, LabelList, ReferenceLine, Tooltip, XAxis, YAxis } from "recharts";
import { ChartConfig } from "app/components/charts/ChartConfig";
import { ChartLabel } from "app/components/charts/ChartLabel";
import { CSSVar } from "app/utils/CSSVar";
import { Date2 } from "app/utils/Date2";
import { DateManager } from "app/managers/DateManager";
import { FBService } from "app/services/FBService";
import { IControlArea } from "app/components/reports/ReportTypes";
import { IProductionTasksByArea } from "app/services/ProductionTypes";
import { ProductionService } from "app/services/ProductionService";


/*****************************************************************************/
interface IProductionAveragesState {
    area: IControlArea;
    startDate: Date;
    endDate: Date;
    productionTasks: IProductionTasksByArea[];
}

class ProductionAverages extends React.Component<{}, IProductionAveragesState> {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    state: IProductionAveragesState = {
        area: ProductionAreas.ALL,
        startDate: new Date(DateManager.lastyear.getTime()),
        endDate: new Date(DateManager.today.getTime()),
        productionTasks: []
    };

    onDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const date = Date2.parseFromInput(e.currentTarget.value);

        if (date) {
            if (e.currentTarget.name === "startDate")
                this.setState({ startDate: date });
            else if (e.currentTarget.name === "endDate")
                this.setState({ endDate: date });
        }
    };

    onUpdateClick = async () => {

        if (this.context.token) {
            this.context.onLoadingChange(true);

            const query = `${this.state.area.query} opened:${Date2.toUSDateString(this.state.startDate)}-${Date2.toUSDateString(this.state.endDate)}`;
            const cases = await FBService.fetchCases(this.context.token, query);
            const productionTasks = ProductionService.getTasksByArea(cases);

            this.setState({ productionTasks });

            this.context.onLoadingChange(false);
        }
    };

    renderControls() {

        return (
            <Form className="controls mb-3">
                <Form.Row className="align-items-end">
                    <Form.Group as={Col} xs={4}>
                        <Form.Label>Area</Form.Label>
                        {Object.keys(ProductionAreas).map((key) => {

                            const data = ProductionAreas[key];

                            return <Form.Check
                                type="radio"
                                label={data.label}
                                name="area"
                                id={data.id}
                                key={data.id}
                                defaultChecked={data.id === this.state.area.id}
                                onClick={() => this.setState({ area: data })}
                                inline
                            />;
                        })}
                    </Form.Group>
                    <Form.Group as={Col} xs={2}>
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control type="date" name="startDate" defaultValue={Date2.toISODateString(this.state.startDate)} onChange={this.onDateChange} />
                    </Form.Group>
                    <Form.Group as={Col} xs={2}>
                        <Form.Label>End Date </Form.Label>
                        <Form.Control type="date" name="endDate" defaultValue={Date2.toISODateString(this.state.endDate)} onChange={this.onDateChange} />
                    </Form.Group>
                    <Form.Group className="text-right" as={Col} xs={4}>
                        <Button onClick={this.onUpdateClick}>Update</Button>
                    </Form.Group>
                </Form.Row>
            </Form>
        );
    }

    renderTabs() {

        if (this.state.productionTasks.length > 0) {
            return (
                <Tabs defaultActiveKey="hourlyAveragesByArea" id="uncontrolled-tab-example" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                    <Tab eventKey="hourlyAveragesByArea" title="Hourly Averages by Area">
                        {this.renderHourlyAveragesByAreaChart()}
                        {this.renderHourlyAveragesByAreaTable()}
                    </Tab>
                    <Tab eventKey="estimateDifferenceByArea" title="Estimate Difference by Area">
                        {this.renderEstimateDifferenceByAreaChart()}
                        {this.renderEstimateDifferenceByAreaTable()}
                    </Tab>
                    <Tab eventKey="data" title="Data">
                        {this.renderData()}
                    </Tab>
                </Tabs>
            );
        }

        return null;
    }

    renderData() {

        if (this.state.productionTasks.length > 0) {

            let eventKey: string;

            const innerHtml = this.state.productionTasks.map((item, index) => {

                eventKey = index.toString();

                return (
                    <Card key={item.ixArea}>
                        <Accordion.Toggle as={Card.Header} eventKey={eventKey}>
                            {this.renderDataHeader(item)}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={eventKey}>
                            <Card.Body>
                                <TaskTable tasks={item.tasks} badgeKey="sProject" dateKey="dtOpened" dateLabel="Opened" />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                );
            });

            return (
                <Row>
                    <Col>
                        <Accordion defaultActiveKey="0">
                            {innerHtml}
                        </Accordion>
                    </Col>
                </Row>
            );
        }

        return null;
    }

    renderDataHeader(area: IProductionTasksByArea) {
        return (
            <Row>
                <Col>
                    <strong>{area.sArea}</strong>&ensp;<Badge variant="info">{area.tasks.length}</Badge>
                </Col>
            </Row>
        );
    }

    renderHourlyAveragesByAreaChart() {

        if (this.state.productionTasks.length > 0) {

            const chartMargin = {
                top: ChartConfig.margin.top,
                bottom: ChartConfig.margin.bottom,
                left: ChartConfig.margin.left,
                right: ChartLabel.outerWidth
            };

            return (
                <Row>
                    <Col className="mb-4">
                        <InfoBox width={CSSVar.px("breakpoint-lg")}>
                            Hourly averages use the following calculation:
                            <br /><span className="formula">&Sigma;(Hours Actual) / &Sigma;(Story Points)</span>
                        </InfoBox>
                        <BarChart margin={chartMargin} barCategoryGap={ChartConfig.barCategoryGap} barGap={ChartConfig.barGap} width={CSSVar.px("breakpoint-lg")} height={ChartConfig.dynamicHeight(this.state.productionTasks.length)} data={this.state.productionTasks} layout="vertical" >
                            <CartesianGrid strokeDasharray="3 3" horizontal={false} />
                            <XAxis type="number" unit="hrs" />
                            <YAxis dataKey="sArea" type="category" width={ChartConfig.axisWidth} />
                            <Tooltip />
                            <Bar dataKey="hrsElapsedAverage" fill={CSSVar.hex("primary")} name="Average" unit="hrs" >
                                <LabelList dataKey="tasks.length" content={ChartLabel.render} />
                            </Bar>
                        </BarChart>
                    </Col>
                </Row>
            );
        }

        return null;
    }

    renderHourlyAveragesByAreaTable() {

        if (this.state.productionTasks.length > 0) {

            const innerHtml = this.state.productionTasks.map((item, index) => {

                return (
                    <tr key={item.ixArea}>
                        <td>{item.sArea}</td>
                        <td className="text-right">{item.hrsElapsedAverage}</td>
                        <td className="text-right">{item.tasks.length}</td>
                    </tr>
                );
            });

            return (
                <Row className="justify-content-center">
                    <Col xs={6}>
                        <Table striped>
                            <thead className="thead-dark">
                                <tr>
                                    <th className="text-center">Area</th>
                                    <th className="text-center">Average Hours</th>
                                    <th className="text-center">Cases</th>
                                </tr>
                            </thead>
                            <tbody>
                                {innerHtml}
                            </tbody>
                        </Table>
                    </Col>
                </Row >
            );
        }

        return null;
    }

    renderEstimateDifferenceByAreaChart() {

        if (this.state.productionTasks.length > 0) {

            return (
                <Row>
                    <Col className="mb-4">
                        <InfoBox width={CSSVar.px("breakpoint-lg")}>
                            The estimate difference is weighted using the following calculation:
                            <br /><span className="formula">&Sigma;(Hours Actual - Hours Estimated) / &Sigma;(Hours Actual)</span>
                        </InfoBox>
                        <BarChart barCategoryGap={ChartConfig.barCategoryGap} barGap={ChartConfig.barGap} width={CSSVar.px("breakpoint-lg")} height={ChartConfig.dynamicHeight(this.state.productionTasks.length)} data={this.state.productionTasks} layout="vertical" >
                            <CartesianGrid strokeDasharray="3 3" horizontal={false} />
                            <XAxis type="number" unit="%" />
                            <YAxis dataKey="sArea" type="category" width={ChartConfig.axisWidth} />
                            <Tooltip />
                            <ReferenceLine x={0} stroke={CSSVar.hex("secondary")} />
                            <Bar dataKey="pctCurrEstWeightedAverage" fill={CSSVar.hex("primary")} name="Estimate Diff" unit="%">
                                {this.state.productionTasks.map(item => <Cell key={item.ixArea} fill={CSSVar.hex((item.pctCurrEstWeightedAverage >= 0) ? "success" : "danger")} />)}
                            </Bar>
                        </BarChart>
                    </Col>
                </Row>
            );
        }

        return null;
    }

    renderEstimateDifferenceByAreaTable() {

        if (this.state.productionTasks.length > 0) {

            const innerHtml = this.state.productionTasks.map((item, index) => {

                return (
                    <tr key={item.ixArea}>
                        <td>{item.sArea}</td>
                        <td className="text-right">{item.pctCurrEstWeightedAverage}%</td>
                    </tr>
                );
            });

            return (
                <Row className="justify-content-center">
                    <Col xs={6}>
                        <Table striped>
                            <thead className="thead-dark">
                                <tr>
                                    <th className="text-center">Area</th>
                                    <th className="text-center">Average Estimate</th>
                                </tr>
                            </thead>
                            <tbody>
                                {innerHtml}
                            </tbody>
                        </Table>
                    </Col>
                </Row >
            );
        }

        return null;
    }

    render() {
        return (
            <div>
                <Row>
                    <Col className="mb-1">
                        <h2>Production Averages</h2>
                    </Col>
                </Row>
                {this.renderControls()}
                {this.renderTabs()}
            </div>
        );
    }
}

export default ProductionAverages;


/*****************************************************************************/
const ProductionAreas: { [key: string]: IControlArea } = {
    ALL: {
        id: "all",
        label: "All",
        query: `status:resolved OR status:closed elapsedtime:0.25..1000 project:'${FBService.UK_PROJECT_PREFIX}'`
    },
    DEV: {
        id: "dev",
        label: "Development",
        query: `status:resolved OR status:closed elapsedtime:0.25..1000 project:'${FBService.UK_PROJECT_PREFIX}' area:Development OR area:'Tech Lead'`
    },
    PM: {
        id: "pm",
        label: "Project Management",
        query: `status:resolved OR status:closed elapsedtime:0.25..1000 project:'${FBService.UK_PROJECT_PREFIX}' area:Management OR area:Testing`
    }
};