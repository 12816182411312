import AppContext from "app/contexts/AppContext";
import React, { FormEvent } from "react";
import { Button, Col, Form } from "react-bootstrap";


interface IAccessTokenState {
    dirty: boolean;
}

class AccessToken extends React.Component<{}, IAccessTokenState> {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    inputEl: React.RefObject<HTMLInputElement>;

    state: IAccessTokenState = {
        dirty: false
    };

    constructor(props = {}) {
        super(props);
        this.inputEl = React.createRef<HTMLInputElement>();
    }

    onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!this.context.token) {
            this.context.onTokenChange(this.inputEl.current?.value);
            this.setState({ dirty: true });
        }
        else {
            this.context.onTokenChange(undefined);
            this.setState({ dirty: false });
        }
    };

    renderInner() {

        if (!this.context.token) {

            const hasError = this.state.dirty && this.context.tokenValid === false;

            return (
                <Form.Row>
                    <Col>
                        <Form.Control type="password" ref={this.inputEl} value={this.context.token} placeholder="Please enter" isInvalid={hasError} />
                        <Form.Text className="text-muted text-right">
                            Get your token from <a href="https://papirfly.fogbugz.com/f/userPrefs/" target="_blank" rel="noopener noreferrer">User Options</a>
                        </Form.Text>
                    </Col>
                </Form.Row>
            );
        }
        else {

            const tokenLength = this.context.token.length;
            const maskedToken = this.context.token.slice(tokenLength - 4, tokenLength).padStart(tokenLength, "*");

            return (
                <Form.Row>
                    <Col>
                        <Form.Text>{maskedToken}</Form.Text>
                    </Col>
                    <Col xs="auto">
                        <Button size="sm" type="submit" variant="secondary">Clear</Button>
                    </Col>
                </Form.Row>
            );
        }
    }


    render() {
        return (
            <Form onSubmit={this.onSubmit}>
                <Form.Label>FogBugz API token</Form.Label>
                {this.renderInner()}
            </Form>
        );
    }
}

export default AccessToken;
