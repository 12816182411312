import AppContext from "app/contexts/AppContext";
import InfoBox from "app/components/reports/InfoBox";
import React from "react";
import TaskTable from "app/components/reports/TaskTable";
import { Accordion, Badge, Button, Card, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { Bar, BarChart, CartesianGrid, Cell, Legend, ReferenceLine, Tooltip, XAxis, YAxis } from "recharts";
import { ChartConfig } from "app/components/charts/ChartConfig";
import { CSSVar } from "app/utils/CSSVar";
import { Date2 } from "app/utils/Date2";
import { DateManager } from "app/managers/DateManager";
import { FBService } from "app/services/FBService";
import { IControlAreaWithPeople } from "app/components/reports/ReportTypes";
import { IFBPerson } from "app/services/FBTypes";
import { IPerformanceByArea, IProductionTasksByArea } from "app/services/ProductionTypes";
import { ProductionService } from "app/services/ProductionService";


/*****************************************************************************/
interface ITeamPerformanceState {
    area: IControlAreaWithPeople;
    person?: IFBPerson;
    startDate: Date;
    endDate: Date;
    personTasks: IProductionTasksByArea[];
    performanceByArea: IPerformanceByArea[];
    team: IFBPerson[];
}

class TeamPerformance extends React.Component<{}, ITeamPerformanceState> {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    state: ITeamPerformanceState = {
        area: PerformanceAreas.DEV,
        startDate: new Date(DateManager.lastyear.getTime()),
        endDate: new Date(DateManager.today.getTime()),
        personTasks: [],
        performanceByArea: [],
        team: []
    };

    onAreaChange = (area: IControlAreaWithPeople) => {
        this.setState({ area });
        this.clearData();
        this.updateTeamMembers();
    };

    onPersonChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const ixPerson = parseInt(e.currentTarget.value);
        const person = this.state.team.find(item => item.ixPerson === ixPerson);
        this.setState({ person });
    };

    onDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const date = Date2.parseFromInput(e.currentTarget.value);

        if (date) {
            if (e.currentTarget.name === "startDate")
                this.setState({ startDate: date });
            else if (e.currentTarget.name === "endDate")
                this.setState({ endDate: date });
        }
    };

    onUpdateClick = async (e: React.MouseEvent) => {

        if (this.context.token) {
            this.context.onLoadingChange(true);

            const query = `${this.state.area.query} opened:${Date2.toUSDateString(this.state.startDate)}-${Date2.toUSDateString(this.state.endDate)}`;
            const cases = await FBService.fetchCases(this.context.token, query);
            const intervals = await FBService.fetchIntervals(this.context.token, this.state.startDate, this.state.endDate, this.state.person?.ixPerson);
            const productionTasks = ProductionService.getTasksByArea(cases);
            const personTasks = ProductionService.getTasksByArea(cases, intervals);
            const performanceByArea = ProductionService.getPerformanceByArea(personTasks, productionTasks);
            this.setState({ personTasks, performanceByArea });

            this.context.onLoadingChange(false);
        }
    };

    componentDidUpdate() {
        this.updateTeamMembers();
    }

    componentDidMount() {
        this.updateTeamMembers();
    }

    clearData() {
        this.setState({
            personTasks: [],
            performanceByArea: [],
            team: []
        });
    }

    updateTeamMembers() {

        if (this.context.people.length > 0 && this.state.team.length === 0) {
            const team = ProductionService.getTeamMembers(this.state.area.people, this.context.people);
            const person = team[0];
            this.setState({ team, person });
        }
    }

    renderControls() {

        return (
            <Form className="controls mb-3">
                <Form.Row className="align-items-end">
                    <Form.Group as={Col} xs={4}>
                        <Form.Label>Area</Form.Label>
                        {Object.keys(PerformanceAreas).map((key, index) => {

                            const data = PerformanceAreas[key];

                            return <Form.Check
                                type="radio"
                                label={data.label}
                                name="area"
                                id={data.id}
                                key={data.id}
                                defaultChecked={data.id === this.state.area.id}
                                onClick={() => this.onAreaChange(data)}
                                inline
                            />;
                        })}
                    </Form.Group>
                    <Form.Group as={Col} xs={2}>
                        <Form.Label>Team Member</Form.Label>
                        <Form.Control as="select" onChange={this.onPersonChange} value={this.state.person?.ixPerson}>
                            {
                                this.state.team.map(item => <option key={item.ixPerson} value={item.ixPerson}>{item.sFullName} </option>)
                            }
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} xs={2}>
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control type="date" name="startDate" defaultValue={Date2.toISODateString(this.state.startDate)} onChange={this.onDateChange} />
                    </Form.Group>
                    <Form.Group as={Col} xs={2}>
                        <Form.Label>End Date </Form.Label>
                        <Form.Control type="date" name="endDate" defaultValue={Date2.toISODateString(this.state.endDate)} onChange={this.onDateChange} />
                    </Form.Group>
                    <Form.Group className="text-right" as={Col} xs={2}>
                        <Button onClick={this.onUpdateClick}>Update</Button>
                    </Form.Group>
                </Form.Row>
            </Form>
        );
    }

    renderTabs() {

        if (this.state.personTasks.length > 0) {

            return (
                <Tabs defaultActiveKey="hourlyAveragesByArea" id="TeamPeformanceTabs" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                    <Tab eventKey="hourlyAveragesByArea" title="Hourly Averages by Area">
                        {this.renderHourlyAveragesByArea()}
                    </Tab>
                    <Tab eventKey="estimateDifferenceByArea" title="Estimate Difference by Area">
                        {this.renderEstimateDifferenceByArea()}
                    </Tab>
                    <Tab eventKey="data" title="Data">
                        {this.renderData()}
                    </Tab>
                </Tabs>
            );
        }

        return null;
    }

    renderData() {

        if (this.state.personTasks.length > 0) {

            let eventKey: string;

            const innerHtml = this.state.personTasks.map((item, index) => {

                eventKey = index.toString();

                return (
                    <Card key={item.ixArea}>
                        <Accordion.Toggle as={Card.Header} eventKey={eventKey}>
                            {this.renderDataHeader(item)}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={eventKey}>
                            <Card.Body>
                                <TaskTable tasks={item.tasks} badgeKey="sProject" dateKey="dtOpened" dateLabel="Opened" />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                );
            });

            return (
                <Row>
                    <Col>
                        <Accordion defaultActiveKey="0">
                            {innerHtml}
                        </Accordion>
                    </Col>
                </Row>
            );
        }

        return null;
    }

    renderDataHeader(area: IProductionTasksByArea) {
        return (
            <Row>
                <Col>
                    <strong>{area.sArea}</strong>&ensp;<Badge variant="info">{area.tasks.length}</Badge>
                </Col>
            </Row>
        );
    }

    renderHourlyAveragesByArea() {

        if (this.state.performanceByArea.length > 0) {

            return (
                <Row>
                    <Col>
                        <InfoBox width={CSSVar.px("breakpoint-lg")}>
                            Hourly averages use the following calculation:
                            <br /><span className="formula">&Sigma;(Hours Actual) / &Sigma;(Story Points)</span>
                        </InfoBox>
                        <BarChart barCategoryGap={ChartConfig.barCategoryGap} barGap={ChartConfig.barGap} width={CSSVar.px("breakpoint-lg")} height={ChartConfig.dynamicHeight(this.state.performanceByArea.length, 2, false, true)} data={this.state.performanceByArea} layout="vertical" >
                            <CartesianGrid strokeDasharray="3 3" horizontal={false} />
                            <XAxis type="number" unit="hrs" />
                            <YAxis dataKey="sArea" type="category" width={ChartConfig.axisWidth} />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="production.hrsElapsedAverage" fill={CSSVar.hex("secondary")} name="Team Average" unit="hrs" />
                            <Bar dataKey="person.hrsElapsedAverage" fill={CSSVar.hex("primary")} name="Individual Average" unit="hrs" />
                        </BarChart>
                    </Col>
                </Row>
            );
        }

        return null;
    }

    renderEstimateDifferenceByArea() {

        if (this.state.performanceByArea.length > 0) {

            return (
                <Row>
                    <Col>
                        <InfoBox width={CSSVar.px("breakpoint-lg")}>
                            The estimate difference is weighted using the following calculation:
                            <br /><span className="formula">&Sigma;(Hours Actual - Hours Estimated) / &Sigma;(Hours Actual)</span>
                        </InfoBox>
                        <BarChart barCategoryGap={ChartConfig.barCategoryGap} barGap={ChartConfig.barGap} width={CSSVar.px("breakpoint-lg")} height={ChartConfig.dynamicHeight(this.state.performanceByArea.length, 2, false, true)} data={this.state.performanceByArea} layout="vertical" >
                            <CartesianGrid strokeDasharray="3 3" horizontal={false} />
                            <XAxis type="number" unit="%" />
                            <YAxis dataKey="sArea" type="category" width={ChartConfig.axisWidth} />
                            <Tooltip />
                            <Legend />
                            <ReferenceLine x={0} stroke={CSSVar.hex("secondary")} />
                            <Bar dataKey="production.pctCurrEstWeightedAverage" fill={CSSVar.hex("secondary")} name="Team Estimate Diff" unit="%" />
                            <Bar dataKey="person.pctCurrEstWeightedAverage" fill={CSSVar.hex("primary")} name="Individual Estimate Diff" unit="%">
                                {this.state.performanceByArea.map(item => <Cell key={item.ixArea} fill={CSSVar.hex((item.person.pctCurrEstWeightedAverage >= 0) ? "success" : "danger")} />)}
                            </Bar>
                        </BarChart>
                    </Col>
                </Row>
            );
        }

        return null;
    }

    render() {
        return (
            <div>
                <Row>
                    <Col className="mb-1">
                        <h2>Team Performance</h2>
                    </Col>
                </Row>
                {this.renderControls()}
                {this.renderTabs()}
            </div>
        );
    }
}

export default TeamPerformance;


/*****************************************************************************/
const PerformanceAreas: { [key: string]: IControlAreaWithPeople } = {
    DEV: {
        id: "dev",
        label: "Development",
        query: `status:resolved OR status:closed project:'${FBService.UK_PROJECT_PREFIX}' area:Development AND -area:'Revision Development' elapsedtime:0.25..1000`,
        people: [
            "andy@papirfly.com",
            "ivan@papirfly.com",
            "jake@papirfly.com",
            "rich@papirfly.com",
            "sefton@papirfly.com"
        ]
    },
    PM: {
        id: "pm",
        label: "Project Management",
        query: `status:resolved OR status:closed project:'${FBService.UK_PROJECT_PREFIX}' area:Management OR area:Testing elapsedtime:0.25..1000`,
        people: [
            "daniel.thorpe@papirfly.com",
            "helen@papirfly.com",
            "katrina@papirfly.com",
            "laura@papirfly.com",
            "scott@papirfly.com"
        ]
    }
};