import AccessToken from "app/components/nav/AccessToken";
import NavOptions from "app/data/NavOptions";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "app/components/nav/Dashboard.css";


interface IDashboardState {
    hidden: boolean;
}


class Dashboard extends React.Component<{}, IDashboardState> {

    state: IDashboardState = {
        hidden: false
    };

    onTabClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ hidden: !this.state.hidden });
    };

    renderLinks() {
        return NavOptions.map(item => {
            return (
                <NavLink key={item.id} exact to={item.path} className="p-2">{item.label}</NavLink>
            );
        });
    }

    render() {
        return (
            <div id="Dashboard" className={this.state.hidden ? "hidden" : ""}>
                <button className="tab" onClick={this.onTabClick} ><i className="material-icons-outlined md-24">menu</i></button>
                <Container className="mt-3">
                    <Row>
                        <Col>
                            <h3>Dashboard</h3>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <AccessToken />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h5>Reports</h5>
                            <div className="nav">
                                {this.renderLinks()}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Dashboard;



