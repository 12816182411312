import OpenCases from "app/components/reports/OpenCases";
import PeopleList from "app/components/reports/PeopleList";
import ProductionAverages from "app/components/reports/ProductionAverages";
import ProductionTotals from "app/components/reports/ProductionTotals";
import ProjectList from "app/components/reports/ProjectList";
import SupportVolume from "app/components/reports/SupportVolume";
import TeamPerformance from "app/components/reports/TeamPerformance";


/*****************************************************************************/
interface INavOption {
    id: string;
    label: string;
    path: string;
    component: any;
}


/*****************************************************************************/
const NavOptions: INavOption[] = [
    {
        id: "projectList",
        label: "Project List",
        path: "/",
        component: ProjectList
    },
    {
        id: "peopleList",
        label: "People List",
        path: "/people",
        component: PeopleList
    },
    {
        id: "openCases",
        label: "Open Cases",
        path: "/open-cases",
        component: OpenCases
    },
    {
        id: "productionAverages",
        label: "Production Averages",
        path: "/production-averages",
        component: ProductionAverages
    },
    {
        id: "productionTotals",
        label: "Production Totals",
        path: "/production-totals",
        component: ProductionTotals
    },
    {
        id: "team",
        label: "Team Performance",
        path: "/team-performance",
        component: TeamPerformance
    }, {
        id: "support",
        label: "Support Volume",
        path: "/support-volume",
        component: SupportVolume
    }
];

export default NavOptions;



