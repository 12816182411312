import App from "app/App";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";


// 2020-06-16: React Bootstrap won't work in StrictMode until updatd.
ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);