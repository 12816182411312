import { Date2 } from "app/utils/Date2";
import { IFBCase } from "app/services/FBTypes";
import { IMonthlySupportMetricsByGroup, ISupportTasksByGroup } from "app/services/SupportTypes";
import { Math2 } from "app/utils/Math2";
import { Object2 } from "app/utils/Object2";


/*****************************************************************************/
export class SupportService {

    static getSupportTasksByGroup(tasks: IFBCase[], groupKey: string) {

        const arr: ISupportTasksByGroup[] = [];
        let group: ISupportTasksByGroup | undefined;
        let sGroup: string;

        tasks.forEach(item => {

            sGroup = Object2.getValue(item, groupKey, "Unknown");
            group = arr.find(a => a.sGroup.toLowerCase() === sGroup.toLowerCase());

            if (!group) {

                group = {
                    tasks: [],
                    ixGroup: arr.length,
                    sGroup: sGroup,
                    hrsElapsedTotal: 0
                };
                arr.push(group);
            }

            group.tasks.push(item);
            group.hrsElapsedTotal += item.hrsElapsed;
        });

        // Round hours and sort tasks by date
        arr.forEach(item => {
            item.tasks.sort((a, b) => a.dtOpened.getTime() - b.dtOpened.getTime());
            item.hrsElapsedTotal = Math2.round(item.hrsElapsedTotal, 0.01);
        });

        return arr.sort((a, b) => a.sGroup.localeCompare(b.sGroup));
    }

    static getSupportCountByGroup(tasks: IFBCase[], groupKey: string, startDate: Date, endDate: Date) {

        const arr: IMonthlySupportMetricsByGroup[] = SupportService.fillMonths([], startDate, endDate);
        let month: IMonthlySupportMetricsByGroup | undefined;
        let sGroup: string;

        tasks.forEach(item => {

            // Month should be returned as we filled the object when instantiating the array.
            month = arr.find(c => c.ixMonth === SupportService.getMonthKey(item.dtOpened));

            if (month) {
                sGroup = Object2.getValue(item, groupKey, "Unknown");

                if (!month.group[sGroup])
                    month.group[sGroup] = {
                        count: 0,
                        hours: 0
                    };

                month.group[sGroup].count++;
                month.group[sGroup].hours += item.hrsElapsed;
            }
        });

        // Add Missing Groups & Round Values
        const labels = SupportService.getGroupLabels(arr);

        for (let i = 0; i < arr.length; i++) {
            for (let j = 0; j < labels.length; j++) {
                if (!arr[i].group[labels[j]]) {
                    arr[i].group[labels[j]] = {
                        count: 0,
                        hours: 0
                    };
                }
                else {
                    arr[i].group[labels[j]].hours = Math2.round(arr[i].group[labels[j]].hours, 0.01);
                }
            }
        }

        return arr;
    }

    static getSupportCustomers(tasks: IFBCase[]) {

        const arr: string[] = [];

        tasks.forEach(item => {
            if (arr.indexOf(item.client) === -1)
                arr.push(item.client);
        });

        return arr.sort((a, b) => a.localeCompare(b));
    }

    static getGroupLabels(supportCountByGroup: IMonthlySupportMetricsByGroup[]) {

        const arr: string[] = [];

        for (let i = 0; i < supportCountByGroup.length; i++) {

            const keys = Object.keys(supportCountByGroup[i].group);

            for (let j = 0; j < keys.length; j++) {
                if (arr.indexOf(keys[j]) === -1)
                    arr.push(keys[j]);
            }
        }

        arr.sort((a, b) => a.localeCompare(b));

        return arr;
    }

    private static fillMonths(target: IMonthlySupportMetricsByGroup[], startDate: Date, endDate: Date) {

        const dt: Date = new Date(startDate.getTime());

        do {

            target.push({
                ixMonth: SupportService.getMonthKey(dt),
                sMonth: SupportService.getMonthLabel(dt),
                group: {}
            });

            dt.setMonth(dt.getMonth() + 1);

        } while (dt.getTime() <= endDate.getTime());

        return target;
    }

    private static getMonthKey(date: Date) {
        return date.getFullYear() * 100 + date.getMonth();
    }

    private static getMonthLabel(date: Date) {
        return `${Date2.months3()[date.getMonth()]}'${date.getFullYear().toString().slice(2, 4)}`;
    }
}
