import AppContext from "app/contexts/AppContext";
import React from "react";
import TaskTable from "app/components/reports/TaskTable";
import { Accordion, Badge, Button, Card, Col, Form, Row, Tab, Table, Tabs } from "react-bootstrap";
import { Bar, BarChart, CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import { ChartConfig } from "app/components/charts/ChartConfig";
import { CSSVar } from "app/utils/CSSVar";
import { Date2 } from "app/utils/Date2";
import { DateManager } from "app/managers/DateManager";
import { FBService } from "app/services/FBService";
import { IControlArea } from "app/components/reports/ReportTypes";
import { IFBCase } from "app/services/FBTypes";
import { IMonthlySupportMetricsByGroup, ISupportTasksByGroup } from "app/services/SupportTypes";
import { Math2 } from "app/utils/Math2";
import { SupportService } from "app/services/SupportService";


/*****************************************************************************/
interface ISupportVolumeState {
    market: IControlArea;
    startDate: Date;
    endDate: Date;
    customer?: string;
    filtered: boolean;
    cases: IFBCase[];
    supportCustomers: string[];
    supportTasksByArea: ISupportTasksByGroup[];
    supportTasksByCustomer: ISupportTasksByGroup[];
    monthlyByArea: IMonthlySupportMetricsByGroup[];
    monthlyAreaLabels: string[];
    supportCaseTotal: number;
    supportHourTotal: number;
}

class SupportVolume extends React.Component<{}, ISupportVolumeState> {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    state: ISupportVolumeState = {
        market: Markets.UK,
        startDate: new Date(DateManager.lastyear.getTime()),
        endDate: new Date(DateManager.today.getTime()),
        filtered: false,
        cases: [],
        supportCustomers: [],
        supportTasksByArea: [],
        supportTasksByCustomer: [],
        monthlyByArea: [],
        monthlyAreaLabels: [],
        supportCaseTotal: 0,
        supportHourTotal: 0
    };

    onDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const date = Date2.parseFromInput(e.currentTarget.value);

        if (date) {
            if (e.currentTarget.name === "startDate")
                this.setState({ startDate: date });
            else if (e.currentTarget.name === "endDate")
                this.setState({ endDate: date });
        }
    };

    onUpdateClick = async (e: React.MouseEvent) => {

        if (this.context.token) {
            this.context.onLoadingChange(true);

            const query = `${this.state.market.query} opened:${Date2.toUSDateString(this.state.startDate)}-${Date2.toUSDateString(this.state.endDate)}`;
            const filtered = false;
            const cases = await FBService.fetchCases(this.context.token, query);
            const supportCustomers = SupportService.getSupportCustomers(cases);
            const customer = supportCustomers[0];

            this.setState({
                customer,
                filtered,
                cases,
                supportCustomers,
                supportTasksByArea: [],
                supportTasksByCustomer: [],
                monthlyByArea: [],
                monthlyAreaLabels: []
            }, this.updateChartData);

            this.context.onLoadingChange(false);
        }
    };

    onCustomerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const customer = e.currentTarget.value;
        this.setState({ customer });
    };

    onFilterClick = (e: React.MouseEvent) => {
        this.setState({ filtered: true }, this.updateChartData);
    };

    onClearClick = (e: React.MouseEvent) => {
        this.setState({ filtered: false }, this.updateChartData);
    };

    updateChartData() {

        const cases = this.state.cases.filter((item) => {
            if (!this.state.filtered || !this.state.customer) return true;
            return item.client === this.state.customer;
        });

        const supportTasksByArea = SupportService.getSupportTasksByGroup(cases, "sArea");
        const supportTasksByCustomer = SupportService.getSupportTasksByGroup(cases, "client");
        const monthlyByArea = SupportService.getSupportCountByGroup(cases, "sArea", this.state.startDate, this.state.endDate);
        const monthlyAreaLabels = SupportService.getGroupLabels(monthlyByArea);
        const supportCaseTotal = supportTasksByArea.reduce((acc, cur) => acc += cur.tasks.length, 0);
        const supportHourTotal = Math2.round(supportTasksByArea.reduce((acc, cur) => acc += cur.hrsElapsedTotal, 0), 0.01);

        this.setState({
            supportTasksByArea,
            supportTasksByCustomer,
            monthlyByArea,
            monthlyAreaLabels,
            supportCaseTotal,
            supportHourTotal
        });
    }

    renderControls() {

        return (
            <Form className="controls mb-3">
                <Form.Row className="align-items-end">
                    <Form.Group as={Col} xs={4}>
                        <Form.Label>Market</Form.Label>
                        {Object.keys(Markets).map((key, index) => {

                            const data = Markets[key];

                            return <Form.Check
                                type="radio"
                                label={data.label}
                                name="market"
                                id={data.id}
                                key={data.id}
                                defaultChecked={data.id === this.state.market.id}
                                onClick={() => this.setState({ market: data })}
                                inline
                            />;
                        })}
                    </Form.Group>

                    <Form.Group as={Col} xs={2}>
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control type="date" name="startDate" defaultValue={Date2.toISODateString(this.state.startDate)} onChange={this.onDateChange} />
                    </Form.Group>
                    <Form.Group as={Col} xs={2}>
                        <Form.Label>End Date </Form.Label>
                        <Form.Control type="date" name="endDate" defaultValue={Date2.toISODateString(this.state.endDate)} onChange={this.onDateChange} />
                    </Form.Group>
                    <Form.Group className="text-right" as={Col} xs={4}>
                        <Button onClick={this.onUpdateClick}>Update</Button>
                    </Form.Group>
                </Form.Row>
                {this.renderDivide()}
                {this.renderFilter()}
            </Form>
        );
    }

    renderDivide() {

        if (this.state.cases.length > 0) {
            return (
                <Row>
                    <Col xs={12}><hr /></Col>
                </Row>
            );
        }

        return null;
    }

    renderFilter() {

        if (this.state.cases.length > 0) {
            return (
                <Form.Row className="align-items-end">
                    <Form.Group as={Col} xs={2}>
                        <Form.Label>Customer</Form.Label>
                        {
                            !this.state.filtered
                                ? <Form.Control as="select" size="sm" onChange={this.onCustomerChange} value={this.state.customer}>
                                    {
                                        this.state.supportCustomers.map((item, index) => <option key={index} value={item}>{item}</option>)
                                    }
                                </Form.Control>
                                : <Form.Text as="span" className="font-weight-bolder form-selected-text">{this.state.customer}</Form.Text>
                        }
                    </Form.Group>
                    <Form.Group className="text-right" as={Col} xs={10}>
                        {
                            !this.state.filtered
                                ? <Button size="sm" onClick={this.onFilterClick}>Apply Filter</Button>
                                : <Button size="sm" variant="secondary" onClick={this.onClearClick}>Clear Filter</Button>
                        }
                    </Form.Group>
                </Form.Row>
            );
        }

        return null;
    }

    renderTabs() {

        if (this.state.supportTasksByArea.length > 0)
            return (
                <Tabs defaultActiveKey="monthlyTotalsByArea" id="uncontrolled-tab-example" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                    <Tab eventKey="monthlyTotalsByArea" title="Monthly Totals by Area">
                        {this.renderMonthlyCasesByArea()}
                        {this.renderMonthlyHoursByArea()}
                    </Tab>
                    <Tab eventKey="totalsByArea" title="Totals by Area">
                        {this.renderTotalsByAreaChart()}
                        {this.renderTotalsByAreaTable()}
                    </Tab>
                    <Tab eventKey="totalsByCustomer" title="Totals by Customer">
                        {this.renderTotalsByCustomerChart()}
                        {this.renderTotalsByCustomerTable()}
                    </Tab>
                    <Tab eventKey="data" title="Data">
                        {this.renderData()}
                    </Tab>
                </Tabs>
            );

        return null;
    }

    renderData() {

        if (this.state.supportTasksByArea.length > 0) {

            let eventKey: string;

            const innerHtml = this.state.supportTasksByArea.map((item, index) => {

                eventKey = index.toString();

                return (
                    <Card key={item.ixGroup}>
                        <Accordion.Toggle as={Card.Header} eventKey={eventKey}>
                            {this.renderDataHeader(item)}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={eventKey}>
                            <Card.Body>
                                <TaskTable tasks={item.tasks} badgeKey="client" dateKey="dtOpened" dateLabel="Opened" />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                );
            });

            return (
                <Row>
                    <Col>
                        <Accordion defaultActiveKey="0">
                            {innerHtml}
                        </Accordion>
                    </Col>
                </Row>
            );
        }

        return null;
    }

    renderDataHeader(area: ISupportTasksByGroup) {
        return (
            <Row>
                <Col>
                    <strong>{area.sGroup}</strong>&ensp;<Badge variant="info">{area.tasks.length}</Badge>
                </Col>
            </Row>
        );
    }

    renderMonthlyCasesByArea() {

        if (this.state.monthlyByArea.length > 0) {

            const colors = ChartConfig.palette(this.state.monthlyByArea.length);

            return (
                <Row className="mb-3">
                    <Col>
                        <LineChart width={CSSVar.px("breakpoint-lg")} height={ChartConfig.height} data={this.state.monthlyByArea} >
                            <CartesianGrid strokeDasharray="3 3" vertical={false} />
                            <XAxis dataKey="sMonth" type="category" />
                            <YAxis />
                            <Tooltip />
                            <Legend align="right" layout="vertical" verticalAlign="middle" wrapperStyle={{ paddingLeft: CSSVar.raw("spacer-3") }} />
                            {
                                this.state.monthlyAreaLabels.map((item, index) => (
                                    <Line key={index} dataKey={`group.${item}.count`} name={item} stroke={colors[index]} strokeWidth={1.5} />
                                ))
                            }
                        </LineChart>
                    </Col>
                </Row>
            );
        }

        return null;
    }

    renderMonthlyHoursByArea() {

        if (this.state.monthlyByArea.length > 0) {

            const colors = ChartConfig.palette(this.state.monthlyByArea.length);

            return (
                <Row className="mb-3">
                    <Col>
                        <LineChart width={CSSVar.px("breakpoint-lg")} height={ChartConfig.height} data={this.state.monthlyByArea} >
                            <CartesianGrid strokeDasharray="3 3" vertical={false} />
                            <XAxis dataKey="sMonth" type="category" />
                            <YAxis unit="hrs" />
                            <Tooltip />
                            <Legend align="right" layout="vertical" verticalAlign="middle" wrapperStyle={{ paddingLeft: CSSVar.raw("spacer-3") }} />
                            {
                                this.state.monthlyAreaLabels.map((item, index) => (
                                    <Line key={index} dataKey={`group.${item}.hours`} name={item} stroke={colors[index]} strokeWidth={1.5} unit="hrs" />
                                ))
                            }
                        </LineChart>
                    </Col>
                </Row>
            );
        }

        return null;
    }

    renderTotalsByAreaChart() {

        if (this.state.supportTasksByArea.length > 0) {

            return (
                <Row>
                    <Col className="mb-4">
                        <BarChart barCategoryGap={ChartConfig.barCategoryGap} barGap={ChartConfig.barGap} width={CSSVar.px("breakpoint-lg")} height={ChartConfig.dynamicHeight(this.state.supportTasksByArea.length, 1, true, true)} data={this.state.supportTasksByArea} layout="vertical">
                            <CartesianGrid strokeDasharray="3 3" horizontal={false} />
                            <XAxis type="number" xAxisId="count" orientation="top" />
                            <XAxis type="number" xAxisId="hours" unit="hrs" />
                            <YAxis dataKey="sGroup" type="category" width={ChartConfig.axisWidth} />
                            <Tooltip />
                            <Legend verticalAlign="bottom" height={ChartConfig.legendHeight} />
                            <Bar dataKey="tasks.length" fill={CSSVar.hex("blue")} name="Total Cases" xAxisId="count" />
                            <Bar dataKey="hrsElapsedTotal" fill={CSSVar.hex("orange")} name="Total Hours" xAxisId="hours" unit="hrs" />
                        </BarChart>
                    </Col>
                </Row>
            );
        }

        return null;
    }

    renderTotalsByAreaTable() {

        if (this.state.supportTasksByArea.length > 0) {

            const innerHtml = this.state.supportTasksByArea.map((item, index) => {

                return (
                    <tr key={item.ixGroup}>
                        <td>{item.sGroup}</td>
                        <td className="text-right">{item.hrsElapsedTotal}</td>
                        <td className="text-right">{item.tasks.length}</td>
                    </tr>
                );
            });

            return (
                <Row className="justify-content-center">
                    <Col xs={6}>
                        <Table striped>
                            <thead className="thead-dark">
                                <tr>
                                    <th className="text-center">Area</th>
                                    <th className="text-center">Hours</th>
                                    <th className="text-center">Cases</th>
                                </tr>
                            </thead>
                            <tbody>
                                {innerHtml}
                            </tbody>
                            <tfoot>
                                <tr className="table-secondary">
                                    <td><strong>Totals:</strong></td>
                                    <td className="text-right">{this.state.supportHourTotal}</td>
                                    <td className="text-right">{this.state.supportCaseTotal}</td>
                                </tr>
                            </tfoot>
                        </Table>
                    </Col>
                </Row >
            );
        }

        return null;
    }

    renderTotalsByCustomerChart() {

        if (this.state.supportTasksByCustomer.length > 0) {

            return (
                <Row>
                    <Col className="mb-4">
                        <BarChart barCategoryGap={ChartConfig.barCategoryGap} barGap={ChartConfig.barGap} width={CSSVar.px("breakpoint-lg")} height={ChartConfig.dynamicHeight(this.state.supportTasksByCustomer.length, 1, true, true)} data={this.state.supportTasksByCustomer} layout="vertical">
                            <CartesianGrid strokeDasharray="3 3" horizontal={false} />
                            <XAxis type="number" xAxisId="count" orientation="top" />
                            <XAxis type="number" xAxisId="hours" unit="hrs" />
                            <YAxis dataKey="sGroup" type="category" width={ChartConfig.axisWidth} />
                            <Tooltip />
                            <Legend verticalAlign="bottom" height={ChartConfig.legendHeight} />
                            <Bar dataKey="tasks.length" fill={CSSVar.hex("blue")} name="Total Cases" xAxisId="count" />
                            <Bar dataKey="hrsElapsedTotal" fill={CSSVar.hex("orange")} name="Total Hours" xAxisId="hours" unit="hrs" />
                        </BarChart>
                    </Col>
                </Row>
            );
        }

        return null;
    }

    renderTotalsByCustomerTable() {

        if (this.state.supportTasksByCustomer.length > 0) {

            const innerHtml = this.state.supportTasksByCustomer.map((item, index) => {

                return (
                    <tr key={item.ixGroup}>
                        <td>{item.sGroup}</td>
                        <td className="text-right">{item.hrsElapsedTotal}</td>
                        <td className="text-right">{item.tasks.length}</td>
                    </tr>
                );
            });

            return (
                <Row className="justify-content-center">
                    <Col xs={6}>
                        <Table striped>
                            <thead className="thead-dark">
                                <tr>
                                    <th className="text-center">Area</th>
                                    <th className="text-center">Hours</th>
                                    <th className="text-center">Cases</th>
                                </tr>
                            </thead>
                            <tbody>
                                {innerHtml}
                            </tbody>
                            <tfoot>
                                <tr className="table-secondary">
                                    <td><strong>Totals:</strong></td>
                                    <td className="text-right">{this.state.supportHourTotal}</td>
                                    <td className="text-right">{this.state.supportCaseTotal}</td>
                                </tr>
                            </tfoot>
                        </Table>
                    </Col>
                </Row >
            );
        }

        return null;
    }

    render() {
        return (
            <div>
                <Row>
                    <Col className="mb-1">
                        <h2>Support Volume</h2>
                    </Col>
                </Row>
                {this.renderControls()}
                {this.renderTabs()}
            </div>
        );
    }
}

export default SupportVolume;


/*****************************************************************************/
const Markets: { [key: string]: IControlArea } = {
    ALL: {
        id: "no",
        label: "Norway",
        query: `project:'${FBService.SUPPORT_PROJECT}' -customer:'${FBService.DK_PROJECT_PREFIX}' -customer:'${FBService.UK_PROJECT_PREFIX}'`
    },
    DK: {
        id: "dk",
        label: "Denmark",
        query: `project:'${FBService.SUPPORT_PROJECT}' customer:'${FBService.DK_PROJECT_PREFIX}'`
    },
    UK: {
        id: "uk",
        label: "United Kingdom",
        query: `project:'${FBService.SUPPORT_PROJECT}' customer:'${FBService.UK_PROJECT_PREFIX}'`
    }
};