import AppContext from "app/contexts/AppContext";
import React from "react";
import TaskTable from "app/components/reports/TaskTable";
import { Accordion, Badge, Button, Card, Col, Form, Row, Tab, Table, Tabs } from "react-bootstrap";
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts";
import { ChartConfig } from "app/components/charts/ChartConfig";
import { CSSVar } from "app/utils/CSSVar";
import { Date2 } from "app/utils/Date2";
import { DateManager } from "app/managers/DateManager";
import { FBService } from "app/services/FBService";
import { IControlArea } from "app/components/reports/ReportTypes";
import { IFBCase } from "app/services/FBTypes";
import { IProductionProject, IProductionTasksByArea, IProductionTasksByProject } from "app/services/ProductionTypes";
import { Math2 } from "app/utils/Math2";
import { ProductionService } from "app/services/ProductionService";


/*****************************************************************************/
interface IProductionTotalsState {
    area: IControlArea;
    startDate: Date;
    endDate: Date;
    ixProject?: number;
    filtered: boolean;
    cases: IFBCase[];
    productionProjects: IProductionProject[];
    productionTasksByArea: IProductionTasksByArea[];
    productionTasksByProject: IProductionTasksByProject[];
    productionCaseTotal: number;
    productionHourTotal: number;
}

class ProductionTotals extends React.Component<{}, IProductionTotalsState> {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    state: IProductionTotalsState = {
        area: ProductionAreas.ALL,
        startDate: new Date(DateManager.lastyear.getTime()),
        endDate: new Date(DateManager.today.getTime()),
        filtered: false,
        cases: [],
        productionProjects: [],
        productionTasksByArea: [],
        productionTasksByProject: [],
        productionCaseTotal: 0,
        productionHourTotal: 0
    };

    onDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const date = Date2.parseFromInput(e.currentTarget.value);

        if (date) {
            if (e.currentTarget.name === "startDate")
                this.setState({ startDate: date });
            else if (e.currentTarget.name === "endDate")
                this.setState({ endDate: date });
        }
    };

    onUpdateClick = async () => {

        if (this.context.token) {
            this.context.onLoadingChange(true);

            const query = `${this.state.area.query} opened:${Date2.toUSDateString(this.state.startDate)}-${Date2.toUSDateString(this.state.endDate)}`;
            const cases = await FBService.fetchCases(this.context.token, query);
            const productionProjects = ProductionService.getProjects(cases);
            const ixProject = productionProjects[0].ixProject;

            this.setState({
                cases,
                productionProjects,
                ixProject
            }, this.updateChartData);

            this.context.onLoadingChange(false);
        }
    };

    onCustomerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const ixProject = Number(e.currentTarget.value);
        this.setState({ ixProject });
    };

    onFilterClick = (e: React.MouseEvent) => {
        this.setState({ filtered: true }, this.updateChartData);
    };

    onClearClick = (e: React.MouseEvent) => {
        this.setState({ filtered: false }, this.updateChartData);
    };

    updateChartData() {

        const cases = this.state.cases.filter((item) => {
            if (!this.state.filtered || !this.state.ixProject) return true;
            return item.ixProject === this.state.ixProject;
        });

        const productionTasksByArea = ProductionService.getTasksByArea(cases);
        const productionTasksByProject = ProductionService.getTasksByProject(cases);
        const productionCaseTotal = productionTasksByArea.reduce((acc, cur) => acc += cur.tasks.length, 0);
        const productionHourTotal = Math2.round(productionTasksByArea.reduce((acc, cur) => acc += cur.hrsElapsedTotal, 0), 0.01);

        this.setState({
            productionTasksByArea,
            productionTasksByProject,
            productionCaseTotal,
            productionHourTotal
        });
    }

    renderControls() {

        return (
            <Form className="controls mb-3">
                <Form.Row className="align-items-end">
                    <Form.Group as={Col} xs={4}>
                        <Form.Label>Area</Form.Label>
                        {Object.keys(ProductionAreas).map((key) => {

                            const data = ProductionAreas[key];

                            return <Form.Check
                                type="radio"
                                label={data.label}
                                name="area"
                                id={data.id}
                                key={data.id}
                                defaultChecked={data.id === this.state.area.id}
                                onClick={() => this.setState({ area: data })}
                                inline
                            />;
                        })}
                    </Form.Group>
                    <Form.Group as={Col} xs={2}>
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control type="date" name="startDate" defaultValue={Date2.toISODateString(this.state.startDate)} onChange={this.onDateChange} />
                    </Form.Group>
                    <Form.Group as={Col} xs={2}>
                        <Form.Label>End Date </Form.Label>
                        <Form.Control type="date" name="endDate" defaultValue={Date2.toISODateString(this.state.endDate)} onChange={this.onDateChange} />
                    </Form.Group>
                    <Form.Group className="text-right" as={Col} xs={4}>
                        <Button onClick={this.onUpdateClick}>Update</Button>
                    </Form.Group>
                </Form.Row>
                {this.renderDivide()}
                {this.renderFilter()}
            </Form>
        );
    }

    renderDivide() {

        if (this.state.cases.length > 0) {
            return (
                <Row>
                    <Col xs={12}><hr /></Col>
                </Row>
            );
        }

        return null;
    }

    renderFilter() {

        if (this.state.cases.length > 0) {

            const project = this.state.productionProjects.find(item => item.ixProject === this.state.ixProject);

            return (
                <Form.Row className="align-items-end">
                    <Form.Group as={Col} xs={2}>
                        <Form.Label>Customer</Form.Label>
                        {
                            !this.state.filtered
                                ? <Form.Control as="select" size="sm" onChange={this.onCustomerChange} value={this.state.ixProject}>
                                    {
                                        this.state.productionProjects.map(item => <option key={item.ixProject} value={item.ixProject}>{item.sProject}</option>)
                                    }
                                </Form.Control>
                                : <Form.Text as="span" className="font-weight-bolder form-selected-text">{project?.sProject}</Form.Text>
                        }
                    </Form.Group>
                    <Form.Group className="text-right" as={Col} xs={10}>
                        {
                            !this.state.filtered
                                ? <Button size="sm" onClick={this.onFilterClick}>Apply Filter</Button>
                                : <Button size="sm" variant="secondary" onClick={this.onClearClick}>Clear Filter</Button>
                        }
                    </Form.Group>
                </Form.Row>
            );
        }

        return null;
    }

    renderTabs() {

        if (this.state.productionTasksByArea.length > 0) {
            return (
                <Tabs defaultActiveKey="totalsByArea" id="uncontrolled-tab-example" className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                    <Tab eventKey="totalsByArea" title="Totals by Area">
                        {this.renderTotalsByAreaChart()}
                        {this.renderTotalsByAreaTable()}
                    </Tab>
                    <Tab eventKey="totalsByProject" title="Totals by Project">
                        {this.renderTotalsByProjectChart()}
                        {this.renderTotalsByProjectTable()}
                    </Tab>
                    <Tab eventKey="data" title="Data">
                        {this.renderData()}
                    </Tab>
                </Tabs>
            );
        }

        return null;
    }

    renderData() {

        if (this.state.productionTasksByArea.length > 0) {

            let eventKey: string;

            const innerHtml = this.state.productionTasksByArea.map((item, index) => {

                eventKey = index.toString();

                return (
                    <Card key={item.ixArea}>
                        <Accordion.Toggle as={Card.Header} eventKey={eventKey}>
                            {this.renderDataHeader(item)}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={eventKey}>
                            <Card.Body>
                                <TaskTable tasks={item.tasks} badgeKey="sProject" dateKey="dtOpened" dateLabel="Opened" />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                );
            });

            return (
                <Row>
                    <Col>
                        <Accordion defaultActiveKey="0">
                            {innerHtml}
                        </Accordion>
                    </Col>
                </Row>
            );
        }

        return null;
    }

    renderDataHeader(area: IProductionTasksByArea) {
        return (
            <Row>
                <Col>
                    <strong>{area.sArea}</strong>&ensp;<Badge variant="info">{area.tasks.length}</Badge>
                </Col>
            </Row>
        );
    }

    renderTotalsByAreaChart() {

        if (this.state.productionTasksByArea.length > 0) {

            return (
                <Row>
                    <Col className="mb-4">
                        <BarChart barCategoryGap={ChartConfig.barCategoryGap} barGap={ChartConfig.barGap} width={CSSVar.px("breakpoint-lg")} height={ChartConfig.dynamicHeight(this.state.productionTasksByArea.length, 1, true, true)} data={this.state.productionTasksByArea} layout="vertical">
                            <CartesianGrid strokeDasharray="3 3" horizontal={false} />
                            <XAxis type="number" xAxisId="count" orientation="top" height={ChartConfig.axisHeight} />
                            <XAxis type="number" xAxisId="hours" unit="hrs" height={ChartConfig.axisHeight} />
                            <YAxis dataKey="sArea" type="category" width={ChartConfig.axisWidth} />
                            <Tooltip />
                            <Legend verticalAlign="bottom" height={ChartConfig.legendHeight} />
                            <Bar dataKey="tasks.length" fill={CSSVar.hex("blue")} name="Total Cases" xAxisId="count" />
                            <Bar dataKey="hrsElapsedTotal" fill={CSSVar.hex("orange")} name="Total Hours" xAxisId="hours" unit="hrs" />
                        </BarChart>
                    </Col>
                </Row>
            );
        }

        return null;
    }

    renderTotalsByAreaTable() {

        if (this.state.productionTasksByArea.length > 0) {

            const innerHtml = this.state.productionTasksByArea.map((item, index) => {

                return (
                    <tr key={item.ixArea}>
                        <td>{item.sArea}</td>
                        <td className="text-right">{item.hrsElapsedTotal}</td>
                        <td className="text-right">{item.tasks.length}</td>
                    </tr>
                );
            });

            return (
                <Row className="justify-content-center">
                    <Col xs={6}>
                        <Table striped>
                            <thead className="thead-dark">
                                <tr>
                                    <th className="text-center">Area</th>
                                    <th className="text-center">Hours</th>
                                    <th className="text-center">Cases</th>
                                </tr>
                            </thead>
                            <tbody>
                                {innerHtml}
                            </tbody>
                            <tfoot>
                                <tr className="table-secondary">
                                    <td><strong>Totals:</strong></td>
                                    <td className="text-right">{this.state.productionHourTotal}</td>
                                    <td className="text-right">{this.state.productionCaseTotal}</td>
                                </tr>
                            </tfoot>
                        </Table>
                    </Col>
                </Row >
            );
        }

        return null;
    }

    renderTotalsByProjectChart() {

        if (this.state.productionTasksByProject.length > 0) {

            return (
                <Row>
                    <Col className="mb-4">
                        <BarChart barCategoryGap={ChartConfig.barCategoryGap} barGap={ChartConfig.barGap} width={CSSVar.px("breakpoint-lg")} height={ChartConfig.dynamicHeight(this.state.productionTasksByProject.length, 1, true, true)} data={this.state.productionTasksByProject} layout="vertical">
                            <CartesianGrid strokeDasharray="3 3" horizontal={false} />
                            <XAxis type="number" xAxisId="count" orientation="top" />
                            <XAxis type="number" xAxisId="hours" unit="hrs" />
                            <YAxis dataKey="sProject" type="category" width={ChartConfig.axisWidth} />
                            <Tooltip />
                            <Legend verticalAlign="bottom" height={ChartConfig.legendHeight} />
                            <Bar dataKey="tasks.length" fill={CSSVar.hex("blue")} name="Total Cases" xAxisId="count" />
                            <Bar dataKey="hrsElapsedTotal" fill={CSSVar.hex("orange")} name="Total Hours" xAxisId="hours" unit="hrs" />
                        </BarChart>
                    </Col>
                </Row>
            );
        }

        return null;
    }

    renderTotalsByProjectTable() {

        if (this.state.productionTasksByProject.length > 0) {

            const innerHtml = this.state.productionTasksByProject.map((item, index) => {

                return (
                    <tr key={item.ixProject}>
                        <td>{item.sProject}</td>
                        <td className="text-right">{item.hrsElapsedTotal}</td>
                        <td className="text-right">{item.tasks.length}</td>
                    </tr>
                );
            });

            return (
                <Row className="justify-content-center">
                    <Col xs={6}>
                        <Table striped>
                            <thead className="thead-dark">
                                <tr>
                                    <th className="text-center">Area</th>
                                    <th className="text-center">Hours</th>
                                    <th className="text-center">Cases</th>
                                </tr>
                            </thead>
                            <tbody>
                                {innerHtml}
                            </tbody>
                            <tfoot>
                                <tr className="table-secondary">
                                    <td><strong>Totals:</strong></td>
                                    <td className="text-right">{this.state.productionHourTotal}</td>
                                    <td className="text-right">{this.state.productionCaseTotal}</td>
                                </tr>
                            </tfoot>
                        </Table>
                    </Col>
                </Row >
            );
        }

        return null;
    }

    render() {
        return (
            <div>
                <Row>
                    <Col className="mb-1">
                        <h2>Production Totals</h2>
                    </Col>
                </Row>
                {this.renderControls()}
                {this.renderTabs()}
            </div>
        );
    }
}

export default ProductionTotals;


/*****************************************************************************/
const ProductionAreas: { [key: string]: IControlArea } = {
    ALL: {
        id: "all",
        label: "All",
        query: `elapsedtime:0.25..1000 project:'${FBService.UK_PROJECT_PREFIX}'`
    },
    DEV: {
        id: "dev",
        label: "Development",
        query: `elapsedtime:0.25..1000 project:'${FBService.UK_PROJECT_PREFIX}' area:Development OR area:'Tech Lead'`
    },
    PM: {
        id: "pm",
        label: "Project Management",
        query: `elapsedtime:0.25..1000 project:'${FBService.UK_PROJECT_PREFIX}' area:Management OR area:Testing`
    }
};