import AppContext from "app/contexts/AppContext";
import React from "react";
import { Col, Row } from "react-bootstrap";


/*****************************************************************************/
class ProjectList extends React.Component {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    renderProjects() {
        return this.context.projects.map(item => {
            return (
                <tr key={item.ixProject} onClick={() => window.open(`https://papirfly.fogbugz.com/f/search/?sSearchFor=project%3A${item.sProject}`)}>
                    <td>{item.sProject}</td>
                    <td>{item.sPersonOwner}</td>
                </tr>
            );
        });
    }

    render() {
        return (
            <div>
                <Row>
                    <Col className="mb-1">
                        <h2>Project List</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <table className="table table-striped interactive">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Project Manager</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderProjects()}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ProjectList;
