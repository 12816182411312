

export class CSSVar {

    static hex(name: string): string {
        const val = getComputedStyle(document.documentElement).getPropertyValue(`--${name}`);

        if (val.indexOf("#") === 0)
            return val;

        const arr = val.replace(/[^\d,]/g, "").split(",");
        if (arr.length === 3) {
            const rgb = arr.map(item => parseInt(item));
            return "#" + ((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1);
        }

        return "#000000";
    }

    static px(name: string): number {
        const val = getComputedStyle(document.documentElement).getPropertyValue(`--${name}`);
        return parseInt(val);
    }

    static raw(name: string): string {
        return getComputedStyle(document.documentElement).getPropertyValue(`--${name}`);
    }
}
