


export class Math2 {

    static clamp(n: number, min: number, max: number): number {
        return Math.min(Math.max(n, min), max);
    }

    /**
     * Rounds upward to the nearest step.
     * @param num The number to round.
     * @param step The step increment to round to.
     */
    static ceil(num: number, step = 1): number {
        return parseFloat((Math.ceil(num / step) * step).toFixed(Math2.countDecimals(step)));
    }

    /**
     * Returns the number of decimals in a number.
     * @param num
     */
    static countDecimals(num: number) {

        const str: string = num.toString();
        const decimalIndex: number = str.indexOf(".");

        if (decimalIndex > -1)
            return str.length - 1 - decimalIndex;

        return 0;
    }

    /**
     * Rounds downward to the nearest step.
     * @param num The number to round.
     * @param step The step increment to round to.
     */
    static floor(num: number, step = 1): number {
        return parseFloat((Math.floor(num / step) * step).toFixed(Math2.countDecimals(step)));
    }

    /**
     * Rounds to the nearest step.
     * @param num The number to round.
     * @param step The step increment to round to.
     */
    static round(num: number, step = 1): number {
        return parseFloat((Math.round(num / step) * step).toFixed(Math2.countDecimals(step)));
    }
}