import AppContext from "app/contexts/AppContext";
import logo from "app/components/reports/logo.svg";
import NavOptions from "app/data/NavOptions";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Route, Switch } from "react-router-dom";
import "app/components/reports/Report.css";


class Report extends React.Component {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    renderRoutes() {
        return NavOptions.map(item => {
            return (
                <Route key={item.id} exact path={item.path} component={item.component} />
            );
        });
    }

    renderBody() {
        if (this.context.token)
            return (
                <Row>
                    <Col>
                        <Switch>
                            {this.renderRoutes()}
                        </Switch>
                    </Col>
                </Row >
            );

        return (
            <Row>
                <Col className="text-center">
                    <em>Please use the dashboard to authentic before viewing a report!</em>
                </Col>
            </Row>
        );
    }

    render() {
        return (
            <Container className="report" fluid>
                <Row>
                    <Col className="mt-4 mb-2 text-center">
                        <img src={logo} width="113" height="32" alt="papirfly" />
                    </Col>
                </Row>
                {this.renderBody()}
            </Container >
        );
    }
}

export default Report;


