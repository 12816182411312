import React from "react";
import { Badge, Col, ProgressBar, Row } from "react-bootstrap";
import { Date2 } from "app/utils/Date2";
import { IFBCase } from "app/services/FBTypes";
import { Math2 } from "app/utils/Math2";
import { Object2 } from "app/utils/Object2";
import "app/components/reports/TaskTable.css";


interface ITaskProps {
    tasks: IFBCase[];
    badgeKey: string;
    dateKey: string;
    dateLabel: string;
    dateFlag?: boolean;
}

class TaskTable extends React.Component<ITaskProps> {

    renderTasks() {

        return this.props.tasks.map(item => {

            const badgeText = Object2.getValue(item, this.props.badgeKey, "Unknown");

            const dateValue = Object2.getValue(item, this.props.dateKey, undefined);
            const dateFlag = (this.props.dateFlag && dateValue && dateValue < new Date()) ? "text-danger" : "";
            const dateFormatted = (dateValue) ? Date2.formatDate(dateValue) : "Unknown";

            return (
                <tr key={item.ixBug} onClick={() => window.open(`https://papirfly.fogbugz.com/f/cases/${item.ixBug}`, undefined, "noopener,noreferrer")}>
                    <td><Badge variant="secondary">{badgeText}</Badge>&ensp;{item.sTitle}</td>
                    <td>{this.renderHours(item.hrsCurrEst, item.hrsElapsed)}</td>
                    <td><small className={dateFlag}>{this.props.dateLabel}: {dateFormatted}</small></td>
                    <td>{this.renderStatus(item.sStatus)}</td>
                </tr>
            );
        });
    }

    renderHours(hrsCurrEst: number, hrsElapsed: number) {

        hrsCurrEst = Math2.round(hrsCurrEst, 0.25);
        hrsElapsed = Math2.round(hrsElapsed, 0.25);

        const percent = Math.min(hrsElapsed / hrsCurrEst * 100, 100);
        const variant = (hrsElapsed > hrsCurrEst) ? "danger" : "success";
        const color = (hrsElapsed > hrsCurrEst) ? "text-danger" : "";

        return (
            <Row className="align-items-center">
                <Col xs={8}>
                    <ProgressBar variant={variant} now={percent} />
                </Col>
                <Col xs={4}>
                    <small className={color}>{hrsElapsed}</small> / <small>{hrsCurrEst}</small>
                </Col>
            </Row>
        );
    }

    renderStatus(sStatus: string) {

        const status = sStatus.toLowerCase();

        if (status.indexOf("resolved") > -1)
            return <small><Badge className="text-uppercase" variant="success">Resolved</Badge></small>;
        else if (status.indexOf("closed") > -1)
            return <small><Badge className="text-uppercase" variant="secondary">Closed</Badge></small>;

        return <small><Badge className="text-uppercase" variant="warning">Open</Badge></small>;
    }

    render() {
        return (
            <table className="table interactive case">
                <tbody>
                    {this.renderTasks()}
                </tbody>
            </table>
        );
    }
}

export default TaskTable;


